const HowToBuy = () => {
  return (
    <>
      <div className="grid sm:grid-cols-2 grid-cols-1 py-16 gap-10 2xl:container mx-auto xl:px-24 lg:px-10 px-5">
        <div>
          <img src="/images/about.png" alt="" className="w-full" />
        </div>
        <div>
          <h2 className="xl:text-7xl md:text-5xl text-4xl font-bold font-grandstander text-primary uppercase">
            How to buy ROCKO
          </h2>
          <p className="mt-6 md:text-xl text-lg font-grandstander font-normal text-primary">
            You can purchase ROCKO in various different ways. We recommend
            downloading Phantom Wallet, purchasing SOL, sending it to your
            phantom address and swap using Phantom's in-app wallet, or on a
            decentralised exchange like Jupiter. You can purchase ROCKO
            multichain using our integrated Mayan swap, or swap using Jupiter
            if you already own some SOL.
          </p>
          <button className="w-56 h-16 hover:bg-primary rounded-[20px] font-grandstander font-medium text-xl flex items-center justify-center gap-3 border-2 border-white text-white hover:text-black bg-transparent mt-6">
            Get Phantom
            <img src="/svgs/phantom-icon.svg" alt="" />
          </button>
        </div>
      </div>
    </>
  );
};

export default HowToBuy;
