import React from "react";
import * as Icons from "../common/Icons";

const featuresData = [
  {
    id: 1,
    featureIcon: "/svgs/buy-bot.svg",
    featureName: "ROCKO Buy Bot",
    featureDesc:
      "Solana's first public Buy Bot on Telegram. Promoting cross-pollination and offering free exposure through 'ROCKO Trending'. As adoption grows, a small fee for trending will be introduced, with a chance for free inclusion. Generated revenue feeds into the Treat Jar (Treasury) for transparency, contributing to ROCKO's growth.",
  },
  {
    id: 2,
    featureIcon: "/svgs/staking.svg",
    featureName: "ROCKO Staking",
    featureDesc:
      "Unlock the full potential of your ROCKO investment with our Staking Pool Utility. Seamlessly stake your coins, earn sustainable rewards, and contribute to Solana's decentralization. User-friendly and secure, it's the hassle-free way to maximize your crypto returns.",
  },
  {
    id: 3,
    featureIcon: "/svgs/utilities.svg",
    featureName: "Further Utilities",
    featureDesc:
      "ROCKO is set to transform the Solana ecosystem, delivering essential utilities for the evolving needs of users. Our commitment ensures a dynamic contribution to Solana's long-term growth, serving hundreds of thousands of users.",
  },
  {
    id: 4,
    featureIcon: "/svgs/buy-bot.svg",
    featureName: "TYPE TO SWAP",
    featureDesc:
      "Type to Swap is one of the core features of Type AI, designed to facilitate seamless token swaps with minimal user input.",
  },
  {
    id: 5,
    featureIcon: "/svgs/staking.svg",
    featureName: "TYPE TO SNIPE",
    featureDesc:
      "Type to Snipe is a feature aimed at providing users an edge in participating in new token launches. Users can type commands to buy a newly launched token at a specific price or as soon as it hits the market. The bot will automatically get the snipe method. Type AI will monitor token launch and execute the purchase on behalf of the user.",
  },
  {
    id: 6,
    featureIcon: "/svgs/utilities.svg",
    featureName: "TYPE TO BRIDGE",
    featureDesc:
      "Type to Bridge facilitates seamless asset transfers between different blockchains. Users can type a command to bridge assets, and Type AI will find the most efficient and cost-effective bridge service to execute the transfer.",
  },
  {
    id: 7,
    featureIcon: "/svgs/buy-bot.svg",
    featureName: "FUTURE FEATURES",
    featureDesc:
      "Type AI's upcoming features will revolutionize user engagement with DeFi, enabling complex protocol execution through simple commands, personalized portfolio management, and smart alert systems, all powered by AI for a seamless crypto experience.",
  },
];

const Features = () => {
  const renderFeatures = featuresData.map((item: any, index: number) => {
    return (
      <React.Fragment key={`${item.id}${index}`}>
        <div className="border-4 border-primary rounded-[24px] lg:px-6 px-4 py-8 features-bg relative lg:pb-32">
          <img src={item.featureIcon} alt="" className="w-20" />
          <h3 className="mt-4 lg:text-4xl text-3xl font-bold font-grandstander text-primary">
            {item.featureName}
          </h3>
          <p className="mt-4 font-grandstander lg:text-xl textx-lg font-normal text-primary">
            {item.featureDesc}
          </p>
          <button className="lg:w-60 w-full h-16 rounded-[25px] text-primary font-grandstander font-medium sm:text-xl text-base flex items-center justify-center gap-3 border-2 border-white hover:text-white hover:bg-transparent lg:absolute bottom-10 lg:mt-0 mt-10">
            Coming Soon
            <img
              src="/svgs/buy-jupiter-icon-white.svg"
              alt=""
              className="lg:block hidden"
            />
          </button>
        </div>
      </React.Fragment>
    );
  });
  return (
    <>
      <div className="2xl:container mx-auto xl:px-24 lg:px-10 px-5 py-20">
        <div className="flex items-center gap-5">
          <h2 className="xl:text-7xl md:text-5xl text-4xl font-grandstander font-bold text-primary">
            Features
          </h2>
          <Icons.FeaturesIcon />
        </div>
        <div className="grid sm:grid-cols-3 grid-cols-1 lg:gap-6 gap-3 mt-20">
          {renderFeatures}
        </div>
      </div>
    </>
  );
};

export default Features;
