import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default function Currencies() {
  return (
    <div className="2xl:container mx-auto xl:px-24 lg:px-10 px-5 py-10">
      <Swiper
        spaceBetween={30}
        slidesPerView={4}
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="currency-card">
            <img src="/images/biconomy.png" alt="" className="currency-img" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="currency-card">
            <img src="/images/xt.png" alt="" className="currency-img" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="currency-card">
            <img src="/svgs/bingx.svg" alt="" className="currency-img" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="currency-card">
            <img src="/svgs/bitmart.svg" alt="" className="currency-img" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="currency-card">
            <img src="/svgs/coinex.svg" alt="" className="currency-img" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="currency-card">
            <img src="/svgs/coinw.svg" alt="" className="currency-img" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="currency-card">
            <img src="/svgs/htx.svg" alt="" className="currency-img" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="currency-card">
            <img src="/svgs/mexc.svg" alt="" className="currency-img" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
