const WhyChooseUs = () => {
  return (
    <>
      <div className="2xl:container mx-auto xl:px-24 lg:px-10 px-5 py-20">
        <h2 className="xl:text-7xl md:text-5xl text-4xl font-grandstander font-bold text-primary">
          Why choose ROCKO?
        </h2>
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5 mt-20">
          <div>
            <img src="/images/img-2.png" alt="" className="w-[12rem]" />
            <p className="text-xl font-grandstander text-primary mt-10">
              ROCKO is a viral narrative based on Solana Co-Founder Raj Gokal’s
              pet dog, becoming a staple in the Solana memecoin space.
            </p>
          </div>
          <div className="lg:mt-28">
            <img
              src="/svgs/img-3.svg"
              alt=""
              className="lg:w-[14rem] w-[13rem]"
            />
            <p className="text-xl font-grandstander text-primary mt-10">
              ROCKO will be more than just a memecoin, featuring several unique
              use cases and utilities that are beneficial to the long term
              growth of the Solana Ecosystem.
            </p>
          </div>
          <div className="lg:mt-56 mt-14">
            <img src="/svgs/img-4.svg" alt="" className="w-[11rem]" />
            <p className="text-xl font-grandstander text-primary mt-10">
              The ROCKO movement is like no other on the Solana blockchain,
              bringing dog lovers from all around the crypto space and uniting
              them into one.
            </p>
          </div>
          <div className="lg:mt-10 mt-14">
            <img src="/svgs/img-5.svg" alt="" className="w-[12rem]" />
            <p className="text-xl font-grandstander text-primary mt-10">
              With the Solana blockchain and coin making a huge return over the
              last few months, it’s time for ROCKO to come in and set the
              stardard for memecoins on Solana. There's a new dog in town.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyChooseUs;
