import React, { useState } from "react";

interface AccordionProps {
  title: string;
  children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`rounded-[24px] border-4 border-primary overflow-hidden transition-all ease-in-out duration-300 px-7 py-6 ${
        isOpen ? "h-full" : "h-24"
      }`}
    >
      <div
        className="cursor-pointer flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="lg:text-4xl sm:text-3xl text-lg font-bold font-grandstander text-primary">
          {title}
        </div>
        <div
          className={`transform-all ease-in-out duration-300 text-white ${
            isOpen ? "rotate-90" : "rotate-0"
          }`}
        >
          &#9654;
        </div>
      </div>
      {isOpen && <div className="mt-4">{children}</div>}
    </div>
  );
};

export default Accordion;
