const FollowUs = () => {
  return (
    <>
      <div
        id="socials"
        className="2xl:container mx-auto xl:px-24 lg:px-10 px-5 py-20 grid sm:grid-cols-2 grid-cols-1 xl:gap-0 gap-10"
      >
        <img src="/svgs/follow-us.svg" alt="" />
        <div>
          <h2 className="xl:text-7xl lg:text-5xl text-4xl font-grandstander font-bold text-primary">
            Follow ROCKO on social media
          </h2>
          <div className="mt-8 flex gap-6 items-center">
            <img src="/images/twitter.png" alt="" />
            <img src="/images/telegram.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FollowUs;
