import * as React from "react";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { styled } from "@mui/system";
import * as Icons from "../common/Icons";

export default function EthButton() {
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? "simple-popper" : undefined;

  return (
    <div>
      <Button aria-describedby={id} type="button" onClick={handleClick}>
        <div className="rounded-[20px] bg-[#44495B] flex gap-3 px-3 py-2">
          <img src="/images/myro.png" alt="" className="w-7" />
          <div className="flex items-center gap-2">
            <h5 className="text-sm text-white font-light">Ethereum </h5>
            <Icons.DownArrowIcon />
          </div>
        </div>
      </Button>
      <BasePopup id={id} open={open} anchor={anchor}>
        <PopupBody>
          <div className="flex gap-3 px-3 py-2">
            <img
              src="https://statics.mayan.finance/assets/SOL.png"
              alt=""
              className="w-7"
            />
            <h5 className="text-base font-urbanist text-white font-medium">Solana </h5>
          </div>
          <div className="flex gap-3 px-3 py-2 mt-5">
            <img
              src="https://statics.mayan.finance/assets/eth.png"
              alt=""
              className="w-7"
            />
            <h5 className="text-base font-urbanist text-white font-medium">Ethereum </h5>
          </div>
          <div className="flex gap-3 px-3 py-2 mt-5">
            <img
              src="https://statics.mayan.finance/assets/bsc.png"
              alt=""
              className="w-7"
            />
            <h5 className="text-base font-urbanist text-white font-medium">BSC </h5>
          </div>
          <div className="flex gap-3 px-3 py-2 mt-5">
            <img
              src="https://statics.mayan.finance/assets/polygon.png"
              alt=""
              className="w-7"
            />
            <h5 className="text-base font-urbanist text-white font-medium">Polygon </h5>
          </div>
        </PopupBody>
      </BasePopup>
    </div>
  );
}

const blue = {
  200: "#99CCFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0066CC",
};

const PopupBody = styled("div")(
  ({ theme }) => `
  width: 16rem;
  padding: 12px 16px;
  margin: 8px;
  border-radius: 20px;
  background: rgb(43, 46, 60);
  z-index: 1;
`
);

const Button = styled("button")(
  ({ theme }) => `
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background-color: ${blue[600]};
  }

  &:active {
    background-color: ${blue[700]};
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
      theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none;
    &:hover {
      background-color: ${blue[500]};
    }
  }
`
);
