import About from "./About";
import BuyUsingJupiter from "./BuyUsingJupiter";
import BuyUsingMayan from "./BuyUsingMayan";
import Currencies from "./Currencies";
import Faqs from "./Faqs";
import Features from "./Features";
import FollowUs from "./FollowUs";
import HowToBuy from "./HowToBuy";
import OurGoal from "./OurGoal";
import Roadmap from "./Roadmap";
import Tokenomics from "./Tokenomics";
import WhyChooseUs from "./WhyChooseUs";

const Home = () => {
  return (
    <>
    {/*  */}
      <div
        id="home"
        className="flex items-center 2xl:container mx-auto xl:px-24 lg:px-10 px-5 h-screen banner-bg"
      >
        <div className="xl:w-[45%] lg:w-3/5 md:w-3/4 w-full">
          <h2 className="font-grandstander text-primary sm:text-7xl text-5xl font-bold">
            Welcome to
          </h2>
          <h2 className="font-black italic banner-text font-grandstander uppercase mt-3 2xl:text-[168px] md:text-[148px] sm:text-[108px] text-7xl">
            ROCKO
          </h2>
          <h6 className="text-xl font-grandstander text-primary font-normal mt-4">
            Rocko the dog: Named after Solana's Co-Founder Raj Gokal's dog Rocky;
            Raj Gokal used to call him <strong>ROCKO</strong> but not{" "}
            <strong>ROCKY</strong>
          </h6>
          <div className="grid sm:grid-cols-2 grid-cols-1 mt-5">
            <button className="sm:w-60 w-full h-16 bg-primary rounded-[20px] font-grandstander font-medium sm:text-xl text-base flex items-center justify-center gap-3 hover:border-2 border-white hover:text-white hover:bg-transparent ">
              Buy on Jupiter
              <img src="/svgs/buy-jupiter-icon.svg" alt="" />
            </button>
            <button className="sm:w-48 w-full h-16 bg-transparent border-2 border-white text-primary hover:bg-primary hover:text-black rounded-[20px] font-grandstander font-medium sm:text-xl text-base sm:mt-0 mt-5">
              Learn More
            </button>
          </div>
        </div>
        <div className="xl:w-[55%] w-1/2 lg:block hidden">
          <img src="/images/img-1.png" alt="" className="w-full" />
        </div>
      </div>
      <Currencies />
      <About />
      <OurGoal />
      <HowToBuy />
      <BuyUsingJupiter />
      <BuyUsingMayan />
      <Features />
      <Tokenomics />
      <Roadmap />
      <FollowUs />
      <WhyChooseUs />
      <Faqs />
    </>
  );
};

export default Home;
