const Footer = () => {
  return (
    <>
      <div className="footer w-full border-t-4 rounded-[50px] flex lg:flex-nowrap flex-wrap items-center lg:justify-start justify-center gap-10 mt-10 2xl:container mx-auto xl:px-24 lg:px-10 px-5 py-10 relative">
        <div>
          <img src="/images/logo_old.png" alt="" />
          <div className="flex items-center gap-6 mt-6">
            <img src="/images/twitter.png" alt="" />
            <img src="/images/telegram.png" alt="" />
          </div>
        </div>
        <div className="xl:w-[36rem] w-[22rem]">
          <p className="text-base font-grandstander text-primary">
            ROCKO is more than just a cryptocurrency, it is also a way to connect
            with other dog meme lovers and make a difference in the world. Join
            the ROCKO movement today and help us make the world a more fun and
            inclusive place for everyone!
          </p>
        </div>
        <div>
          <img
            src="/images/img-1.png"
            alt=""
            className="lg:absolute -top-32 xl:right-0 -right-14"
          />
        </div>
      </div>
    </>
  );
};

export default Footer;
