import Accordion from "../common/Accordions";

const Faqs = () => {
  return (
    <>
      <div className="2xl:container mx-auto xl:px-24 lg:px-10 px-5 py-20">
        <div className="flex items-center gap-5">
          <h2 className="xl:text-7xl md:text-5xl text-4xl font-grandstander font-bold text-primary">
            FAQ
          </h2>
          <img src="/svgs/faq-icon.svg" alt="" />
        </div>
        <div className="mt-10 space-y-5">
          <Accordion title="How do I Buy and Sell ROCKO?">
            <p className="text-primary font-grandstander text-xl">
              You can trade ROCKO on any of our listed Centralised Exchanges, or
              on Decentralised Exchanges like Jupiter and Raydium.
            </p>
          </Accordion>
          <Accordion title="Will ROCKO list on more CEX's?">
            <p className="text-primary font-grandstander text-xl">
              Yes, ROCKO will be listed on most major worldwide exchanges.
            </p>
          </Accordion>
          <Accordion
            title="Is there a ROCKO whitepaper / roadmap?
"
          >
            <p className="text-primary font-grandstander text-xl">
              Yes, you can view our whitepaper here.
            </p>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default Faqs;
