const About = () => {
  return (
    <>
      <div id="about" className="grid sm:grid-cols-2 grid-cols-1 py-16 gap-10 2xl:container mx-auto xl:px-24 lg:px-10 px-5">
        <div>
          <img src="/images/ig.png" alt="" className="w-full" />
        </div>
        <div>
          <h2 className="xl:text-7xl md:text-5xl text-4xl font-bold font-grandstander text-primary uppercase">
            About ROCKO
          </h2>
          <p className="mt-6 md:text-xl text-lg font-grandstander font-normal text-primary">
            Many may ask, what is ROCKO? ROCKO is the name of the dog owned by Raj
            Gokal, one of the co-founders of Solana. This project pays homage to
            him, and his dog, and responds to the popular demand for dog-based
            narratives in the crypto space. ROCKO was created in response to this
            demand, and to provide value to the ecosystem created by Raj and his
            partner — the Solana blockchain.
          </p>
          <p className="md:mt-12 mt-6 md:text-xl text-lg font-grandstander font-normal text-primary">
            ROCKO is more than just a cryptocurrency, it is also a movement. We
            believe that Solana has the potential to change the world for the
            better, and we are committed to making it more inclusive and
            welcoming for everyone.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
