import { useState } from "react";
import * as Icons from "../common/Icons";

const BuyUsingJupiter = () => {
  const [showMoreWallet, setShowMoreWallet] = useState<boolean>(false);
  const [showConnectWallet, setShowConnectWallet] = useState<boolean>(false);
  const [showControls, setShowControls] = useState<boolean>(false);
  const [showSelectToken, setShowSelectToken] = useState<boolean>(false);
  return (
    <>
      <div className="flex md:flex-row flex-col justify-between items-center 2xl:container mx-auto xl:px-24 lg:px-10 px-5 py-16">
        <div className="h-full md:mt-0 mb-10">
          <h2 className="xl:text-7xl lg:text-5xl text-4xl font-grandstander font-bold text-primary">
            Buy using <br className="md:block hidden" /> Jupiter:
          </h2>
          <p className="mt-6 text-xl font-grandstander font-normal text-primary xl:w-auto lg:w-96">
            Already have SOL in your wallet? Swap using Jupiter below.
          </p>
        </div>
        <div className="sm:w-auto w-full">
          <div className="sm:w-96 w-full relative">
            <div className="flex items-center justify-between">
              <div className="flex gap-3 items-center">
                <img src="/svgs/jupiter-logo.svg" alt="" />
                <p className="text-sm font-bold text-primary">Jupiter</p>
              </div>
              <div className="flex items-center gap-2">
                <div className="p-2 cursor-pointer text-white border border-white rounded-full">
                  <Icons.ReloadIcon />
                </div>
                <div
                  onClick={() => setShowControls(true)}
                  className="p-2 cursor-pointer text-white border border-white rounded-full flex items-center gap-2"
                >
                  <Icons.ControlIcon />
                  <p className="text-xs">0.1%</p>
                </div>
                <div className="p-2 cursor-pointer text-white border border-white rounded-full">
                  <p
                    onClick={() => setShowConnectWallet(true)}
                    className="text-xs"
                  >
                    Connect Wallet
                  </p>
                </div>
              </div>
            </div>
            <div className="py-5 w-full bg-[#212129] mt-4 rounded-lg px-4 flex items-center justify-between">
              <div>
                <div
                  onClick={() => setShowSelectToken(true)}
                  className="rounded-[20px] bg-[#4D4D53] flex gap-5 px-3 py-2 cursor-pointer"
                >
                  <img src="/images/usdc.png" alt="" className="w-7" />
                  <div className="flex items-center gap-2">
                    <h5 className="font-semibold text-base text-white">USDC</h5>
                    <Icons.DownArrowIcon />
                  </div>
                </div>
                <div className="flex items-center gap-2 mt-3">
                  <Icons.UsdcIcon />
                  <p className="text-[#7A7A7E] text-xs">0.000000 USDC</p>
                </div>
              </div>
              <p className="text-lg text-[#7A7A7E] font-medium">0.00</p>
            </div>
            <div className="py-5 w-full bg-[#212129] mt-4 rounded-lg px-4 flex items-center justify-between">
              <div>
                <div className="rounded-[20px] bg-[#4D4D53] flex gap-5 px-3 py-2">
                  <img src="/images/usdc.png" alt="" className="w-7" />
                  <div className="flex items-center gap-2">
                    <h5 className="font-semibold text-base text-white">
                      ROCKO
                    </h5>
                  </div>
                </div>
                <div className="flex items-center gap-2 mt-3">
                  <Icons.UsdcIcon />
                  <p className="text-[#7A7A7E] text-xs">0.000000 ROCKO</p>
                </div>
              </div>
              <p className="text-lg text-[#7A7A7E] font-medium"></p>
            </div>
            <button className="py-5 w-full bg-[#191B1F] mt-4 rounded-lg text-white font-semibold text-base">
              Connect Wallet
            </button>
            {/* Controls Popup */}
            <div
              className={`absolute top-0 h-full w-full bg-[#3A3B44] inset-x-0 rounded-xl py-5 ${
                showControls ? "block" : "hidden"
              }`}
            >
              <div className="flex justify-between item-center w-full px-5">
                <h3 className="text-base text-white font-bold font-urbanist">
                  Swap Settings
                </h3>
                <div
                  onClick={() => setShowControls(false)}
                  className="text-white cursor-pointer"
                >
                  <Icons.CloseIconSm />
                </div>
              </div>
              <div className="w-full h-[1px] bg-gray-500 mt-4"></div>
              <div className="px-5 h-[93%] overflow-y-auto">
                <p className="text-base text-gray-300 font-urbanist mt-3">
                  Transaction Priority
                </p>
                <div className="grid grid-cols-3 mt-3 w-full h-[4.5rem] bg-[#1B1B1E] rounded-2xl">
                  <div className="rounded-tl-2xl rounded-bl-2xl h-full w-full hover:bg-[#3A3B44] flex flex-col items-center justify-center border-r hover:border border-gray-600 hover:border-blue-300">
                    <p className="text-base font-semibold text-gray-300 font-urbanist">
                      Normal
                    </p>
                    <p className="text-sm font-normal text-gray-400 font-urbanist">
                      0 SOL
                    </p>
                  </div>
                  <div className="h-full w-full hover:bg-[#3A3B44] flex flex-col items-center justify-center border-r hover:border border-gray-600 hover:border-blue-300">
                    <p className="text-base font-semibold text-gray-300 font-urbanist">
                      High
                    </p>
                    <p className="text-sm font-normal text-gray-400 font-urbanist">
                      0.000025 SOL
                    </p>
                  </div>
                  <div className="rounded-tr-2xl rounded-br-2xl h-full w-full hover:bg-[#3A3B44] flex flex-col items-center justify-center hover:border border-gray-600 hover:border-blue-300">
                    <p className="text-base font-semibold text-gray-300 font-urbanist">
                      Turbo
                    </p>
                    <p className="text-sm font-normal text-gray-400 font-urbanist">
                      0.005 SOL
                    </p>
                  </div>
                </div>
                <p className="text-sm text-gray-300 font-urbanist mt-3">
                  or set manually:
                </p>
                <div className="flex items-center justify-between bg-[#1B1B1E] rounded-2xl mt-2 px-5">
                  <input
                    type="text"
                    placeholder="0.0000"
                    className="h-14 w-full bg-transparent focus:border-0 focus:outline-none focus:ring-0 text-white font-urbanist"
                  />
                  <p className="text-xs text-gray-300">SOL</p>
                </div>
                <p className="text-xs text-gray-400 mt-1">
                  This will cost an additional 0 SOL.
                </p>
                <div className="my-5 w-full h-[1px] bg-gray-500"></div>
                <p className="text-xs text-gray-400 mt-1">Slippage Settings</p>
                <div className="grid grid-cols-5 mt-3 w-full h-16 bg-[#1B1B1E] rounded-2xl mb-6">
                  <div className="rounded-tl-2xl rounded-bl-2xl h-full w-full hover:bg-[#3A3B44] flex flex-col items-center justify-center border-r hover:border border-gray-600 hover:border-blue-300">
                    <p className="text-base font-normal text-gray-300 font-urbanist">
                      0.1%
                    </p>
                  </div>
                  <div className="h-full w-full hover:bg-[#3A3B44] flex flex-col items-center justify-center border-r hover:border border-gray-600 hover:border-blue-300">
                    <p className="text-base font-normal text-gray-300 font-urbanist">
                      0.5%
                    </p>
                  </div>
                  <div className="h-full w-full hover:bg-[#3A3B44] flex flex-col items-center justify-center border-r hover:border border-gray-600 hover:border-blue-300">
                    <p className="text-base font-normal text-gray-300 font-urbanist">
                      1%
                    </p>
                  </div>
                  <div className="rounded-tr-2xl rounded-br-2xl col-span-2 h-full w-full hover:bg-[#3A3B44] flex gap-2 items-center justify-center hover:border border-gray-600 hover:border-blue-300">
                    <p className="text-base font-normal text-gray-200 font-urbanist">
                      Custom
                    </p>
                    <p className="text-base font-normal text-gray-400 font-urbanist">
                      0.00%
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Select Token Popup */}
            <div
              className={`absolute top-0 h-full w-full bg-[#3A3B44] inset-x-0 rounded-xl py-5 px-5 ${
                showSelectToken ? "block" : "hidden"
              }`}
            >
              <div className="flex justify-between item-center w-full">
                <h3 className="text-base text-white font-bold font-urbanist">
                  Select Token
                </h3>
                <div
                  onClick={() => setShowSelectToken(false)}
                  className="text-white cursor-pointer"
                >
                  <Icons.CloseIconSm />
                </div>
              </div>
              <div className="flex items-center h-14 mt-4 w-full bg-[#1B1B1E] rounded-2xl px-4">
                <div className="w-10 text-white">
                  <Icons.SearchIcon />
                </div>
                <input
                  type="text"
                  placeholder="Search"
                  className="h-full w-full bg-transparent focus:border-0 focus:outline-none focus:ring-0 text-white font-urbanist"
                />
              </div>
              <div className="h-[73%] overflow-y-auto mt-2">
                <div className="flex items-center gap-5 mt-5 bg-[#2C2D33] hover:bg-black/10 px-5 h-14 rounded-2xl">
                  <div className="w-10">
                    <img src="/images/usdc.png" alt="" className="w-8" />
                  </div>
                  <div>
                    <h3 className="text-sm text-white font-medium">USDC</h3>
                    <p className="text-gray-500 text-xs">0.00000</p>
                  </div>
                </div>
                <div className="flex items-center gap-5 mt-2 bg-[#2C2D33] hover:bg-black/10 px-5 h-14 rounded-2xl">
                  <div className="w-10">
                    <img src="https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png" alt="" className="w-8" />
                  </div>
                  <div>
                    <h3 className="text-sm text-white font-medium">SOL</h3>
                    <p className="text-gray-500 text-xs">0.00000</p>
                  </div>
                </div>
                <div className="flex items-center gap-5 mt-2 bg-[#2C2D33] hover:bg-black/10 px-5 h-14 rounded-2xl">
                  <div className="w-10">
                    <img src="https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg" alt="" className="w-8" />
                  </div>
                  <div>
                    <h3 className="text-sm text-white font-medium">USDT</h3>
                    <p className="text-gray-500 text-xs">0.00000</p>
                  </div>
                </div>
                <div className="flex items-center gap-5 mt-2 bg-[#2C2D33] hover:bg-black/10 px-5 h-14 rounded-2xl">
                  <div className="w-10">
                    <img src="https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So/logo.png" alt="" className="w-8" />
                  </div>
                  <div>
                    <h3 className="text-sm text-white font-medium">mSOL</h3>
                    <p className="text-gray-500 text-xs">0.00000</p>
                  </div>
                </div>
                <div className="flex items-center gap-5 mt-2 bg-[#2C2D33] hover:bg-black/10 px-5 h-14 rounded-2xl">
                  <div className="w-10">
                    <img src="https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/ETH_wh.png" alt="" className="w-8" />
                  </div>
                  <div>
                    <h3 className="text-sm text-white font-medium">ETH</h3>
                    <p className="text-gray-500 text-xs">0.00000</p>
                  </div>
                </div>
                <div className="flex items-center gap-5 mt-2 bg-[#2C2D33] hover:bg-black/10 px-5 h-14 rounded-2xl mb-5">
                  <div className="w-10">
                    <img src="https://raw.githubusercontent.com/wormhole-foundation/wormhole-token-list/main/assets/USDCet_wh.png" alt="" className="w-8" />
                  </div>
                  <div>
                    <h3 className="text-sm text-white font-medium">USDCeT</h3>
                    <p className="text-gray-500 text-xs">0.00000</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Connect Wallet Popup */}
      <div
        className={`fixed top-0 w-full h-full collect-wallet-bg z-[9999] flex items-center justify-center ${
          showConnectWallet ? "block" : "hidden"
        }`}
      >
        <div className="bg-[#313e4c] px-8 py-6 rounded-2xl w-[30rem] relative">
          <h3 className="text-base text-white font-bold font-urbanist">
            Connect Wallet
          </h3>
          <p className="text-xs text-white font-urbanist">
            You need to connect a Solana wallet.
          </p>
          <div
            onClick={() => setShowConnectWallet(false)}
            className="text-white w-6 absolute top-5 right-5 cursor-pointer"
          >
            <Icons.CloseIconSm />
          </div>
          <h3 className="text-sm text-white font-bold font-urbanist mt-6">
            Popular wallets
          </h3>
          <div className="mt-5 grid grid-cols-3 gap-5">
            <div className="hover:bg-[#46525e] cursor-pointer h-24 w-full rounded-lg flex flex-col items-center justify-center">
              <img src="/svgs/phantom.svg" alt="" className="w-10" />
              <p className="text-sm font-medium mt-2 text-white font-urbanist">
                Phantom
              </p>
            </div>
            <div className="hover:bg-[#46525e] cursor-pointer h-24 w-full rounded-lg flex flex-col items-center justify-center">
              <img src="/svgs/solflare.svg" alt="" className="w-10" />
              <p className="text-sm font-medium mt-2 text-white font-urbanist">
                Solflare
              </p>
            </div>
            <div className="hover:bg-[#46525e] cursor-pointer h-24 w-full rounded-lg flex flex-col items-center justify-center">
              <img src="/images/backpack.png" alt="" className="w-10" />
              <p className="text-sm font-medium mt-2 text-white font-urbanist">
                Backpack
              </p>
            </div>
          </div>
          <div
            onClick={() => setShowMoreWallet(!showMoreWallet)}
            className="flex justify-between items-center mt-5 cursor-pointer"
          >
            <h3 className="text-sm text-white font-bold font-urbanist">
              More wallets
            </h3>
            <div className={`${showMoreWallet ? "rotate-180" : "rotate-0"}`}>
              <Icons.DownArrowIconSm />
            </div>
          </div>
          <div
            className={`mt-5 grid grid-cols-2 gap-5 overflow-hidden transition-all ease-in-out duration-300 ${
              showMoreWallet ? "h-28" : "h-0"
            }`}
          >
            <div className="hover:bg-[#46525e] cursor-pointer h-24 w-full rounded-lg flex items-center justify-center gap-5">
              <img src="/images/okx-icon.png" alt="" className="w-10" />
              <p className="text-sm font-bold mt-2 text-white font-urbanist">
                OKX Wallet
              </p>
            </div>
            <div className="hover:bg-[#46525e] cursor-pointer h-24 w-full rounded-lg flex items-center justify-center gap-5">
              <img src="/images/glow-icon.png" alt="" className="w-10" />
              <p className="text-sm font-bold mt-2 text-white font-urbanist">
                Glow
              </p>
            </div>
          </div>
          <p className="text-sm font-bold mt-5 text-gray-100 underline font-urbanist cursor-pointer">
            I don't have a wallet
          </p>
        </div>
      </div>
    </>
  );
};

export default BuyUsingJupiter;
